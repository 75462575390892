<template>
    <div class="relative min-h-screen w-full">
        <header class="h-32 bg-gradient-to-r from-blue-800 to-blue-500"/>
        <main class="-mt-16 pb-8">


            <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 class="sr-only">Profile</h1>
                <!-- Main 3 column grid -->
                <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                    <!-- Left column -->
                    <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                        <!-- Profile -->
                        <section aria-labelledby="profile-overview-title">
                            <div class="rounded-lg bg-white overflow-hidden shadow">
                                <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                                <div class="bg-white p-6">
                                    <div class="sm:flex sm:items-center sm:justify-between">
                                        <div class="sm:flex sm:space-x-5">
                                            <div class="flex-shrink-0">
                                                <v-gravatar :email="$store.getters['master/user'].email" class="mx-auto h-20 w-20 rounded-full" alt=""/>
                                            </div>
                                            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                                <p class="text-sm font-medium text-gray-600">Welcome back,</p>
                                                <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                                                    {{ $store.getters['master/user'].first_name }} {{ $store.getters['master/user'].last_name }}
                                                </p>
                                                <p class="text-sm font-medium text-gray-600">{{ $store.getters['master/unit'].name }} <b>{{ $store.getters['master/brand'].name }}</b><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="mt-5 flex justify-center sm:mt-0">
                                            <button @click="goToProfile"
                                                    class="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                                View profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <!-- Email issues -->
                        <section class="rounded-lg bg-white shadow">
                            <h2 class="text-xl font-semibold text-gray-900 px-6 pt-6">Email issues</h2>
                            <div class="text-base leading-6 text-gray-500 mb-5 px-6">The the email issues below</div>
                            <div v-if="loading" class="mb-6">
                                <autralis-loading-comp size="xs"/>
                            </div>
                            <div v-else class="sm:grid sm:grid-cols-4">
                                <div class="flex flex-col border-b border-gray-100 p-4 text-center sm:border-0 sm:border-r">
                                    <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                        Bounces
                                    </dt>
                                    <dd class="order-1 text-3xl font-extrabold text-blue-600">
                                        {{ emailStats.bounce }}
                                    </dd>
                                </div>
                                <div class="flex flex-col border-t border-b border-gray-100 p-4 text-center sm:border-0 sm:border-l sm:border-r">
                                    <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                        Blocks
                                    </dt>
                                    <dd class="order-1 text-3xl font-extrabold text-blue-600">
                                        {{ emailStats.block }}
                                    </dd>
                                </div>
                                <div class="flex flex-col border-t border-gray-100 p-4 text-center sm:border-0 sm:border-l">
                                    <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                        Invalid
                                    </dt>
                                    <dd class="order-1 text-3xl font-extrabold text-blue-600">
                                        {{ emailStats.invalid }}
                                    </dd>
                                </div>
                                <div class="flex flex-col border-t border-gray-100 p-4 text-center sm:border-0 sm:border-l">
                                    <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                                        Spam
                                    </dt>
                                    <dd class="order-1 text-3xl font-extrabold text-blue-600">
                                        {{ emailStats.spam }}
                                    </dd>
                                </div>
                            </div>
                        </section>

                        <!-- Contact -->

                        <section class="rounded-lg bg-white shadow">
                            <div class="p-6">
                                <autralis-loading-comp v-if="loading" size="xs"/>

                                <div v-else class="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
                                    <div>
                                        <h2 class="text-xl font-semibold text-gray-900">Contact</h2>
                                        <div class="text-base text-gray-500">If you have any questions feel free to contact us.</div>
                                        <div class="mt-9 space-y-2">
                                            <div v-if="contact.url" class="flex items-center">
                                                <div class="w-6 mr-3"><i class="fal fa-globe-europe text-gray-500 text-sm"/></div>
                                                <a :href="contact.url" class="text-gray-500 hover:text-blue-500" target="_blank">{{ contact.url }}</a>
                                            </div>

                                            <div v-if="contact.email" class="flex items-center">
                                                <div class="w-6 mr-3"><i class="fal fa-envelope text-gray-500 text-sm"/></div>
                                                <a :href="'mailto:' + contact.email" class="text-gray-500 hover:text-blue-500">{{ contact.email }}</a>
                                            </div>

                                            <div v-if="contact.phone && contact.phone.nl" class="flex items-center">
                                                <div class="w-6 mr-3"><i class="fal fa-phone text-gray-500 text-sm"/></div>
                                                <a :href="'tel:' + contact.phone.nl" class="text-gray-500 hover:text-blue-500">{{ contact.phone.nl }} (NL)</a>
                                            </div>
                                            <div v-if="contact.phone && contact.phone.fr" class="flex items-center">
                                                <div class="w-6 mr-3"><i class="fal fa-phone text-gray-500 text-sm"/></div>
                                                <a :href="'tel:' + contact.phone.fr" class="text-gray-500 hover:text-blue-500">{{ contact.phone.fr }} (FR)</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="openingsHours.length > 0" class="mt-12 sm:mt-16 md:mt-0">
                                        <h2 class="text-xl font-semibold text-gray-900">Opening hours</h2>
                                        <div class="text-base leading-6 text-gray-500">Below you can find our office's opening hours</div>
                                        <div class="mt-9 space-y-2">
                                            <div v-for='item in openingsHours' :key="item.day" class="flex items-center">
                                                <div class="text-gray-500 mr-2" style="min-width: 95px;">{{ item.day }}</div>
                                                <div v-if="item.hours.length > 0" :class="['flex ', isToday(item.day) ? 'text-blue-600 font-semibold' : 'text-gray-500']">
                                                    <div v-if="item.hours[0]">{{ item.hours[0] }}</div>
                                                    <div v-if="item.hours[1]" class="ml-2"> | {{ item.hours[1] }}</div>
                                                </div>
                                                <div v-else :class="['flex ', isToday(item.day) ? 'text-blue-600 font-semibold' : 'text-gray-500']">Closed</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <!-- Right column -->
                    <div class="grid grid-cols-1 gap-4">
                        <!-- Messages -->
                        <section aria-labelledby="recent-hires-title">
                            <div class="rounded-lg bg-white overflow-hidden shadow">
                                <div class="p-6">
                                    <h2 class="text-xl font-semibold text-gray-900">Messages</h2>
                                    <div class="text-base leading-6 text-gray-500">Check the latest messages below</div>
                                    <div class="flow-root mt-9">
                                        <autralis-loading-comp v-if="loading" size="xs"/>
                                        <div v-else-if="!loading && messages.length === 0" class="text-center">
                                            <i class="fal fa-envelope text-gray-600 text-3xl"/>
                                            <p class="mt-1 text-sm text-gray-500">
                                                No messages at the moment.
                                            </p>
                                        </div>
                                        <div v-else class="flex flex-col">
                                            <ul role="list" class="-my-5 divide-y divide-gray-200">
                                                <li v-for="message in messages" :key="message.id" class="py-4">
                                                    <div class="flex items-center space-x-4">
                                                        <div class="flex-shrink-0">
                                                            <i :class="['text-3xl text-gray-600', message.read ? 'fal fa-envelope-open' : 'fal fa-envelope']"/>
                                                        </div>
                                                        <div class="min-w-0 flex-1 cursor-pointer" @click="goToMessage(message.id)">
                                                            <p class="text-sm font-medium text-gray-900">{{ message.from }}</p>
                                                            <p class="text-sm text-gray-500">
                                                                <time datetime="2020-12-09T11:43:00">{{ formatDate(message.sent) }}</time>
                                                            </p>
                                                        </div>
                                                        <div class="hidden xl:block">
                                                            <button @click="goToMessage(message.id)"
                                                                    class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full
                                                                text-gray-700 bg-white hover:bg-gray-50">
                                                                View
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="mt-6">
                                                <button @click="viewAllMessages"
                                                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                                    View all
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </section>

                        <!-- News -->
                        <section aria-labelledby="announcements-title">
                            <div class="rounded-lg bg-white overflow-hidden shadow">
                                <div class="py-6">
                                    <h2 class="text-xl font-semibold text-gray-900 px-6" id="announcements-title">News</h2>
                                    <div class="text-base leading-6 text-gray-500 px-6">Check the latest news below</div>
                                    <div v-if="loading" class="mt-9">
                                        <autralis-loading-comp size="xs"/>
                                    </div>
                                    <div v-else-if="!loading && news.length === 0" class="text-center px-6">
                                        <i class="fal fa-newspaper text-gray-600 text-3xl"/>
                                        <p class="mt-1 text-sm text-gray-500">
                                            No news at the moment.
                                        </p>
                                    </div>
                                    <div v-else class="flex flex-col">
                                        <div class="flow-root mt-9">
                                            <ul role="list" class="-my-5 divide-y divide-gray-200">
                                                <li v-for="item in news" :key="item.id" class="py-4 cursor-pointer hover:bg-gray-50 px-6">
                                                    <div class="flex items-center space-x-4">
                                                        <div class="min-w-0 flex-1" @click="goToNews(item.id)">
                                                            <p class="text-sm font-medium text-gray-900">{{ item.title }}</p>
                                                            <p class="text-sm text-gray-500">
                                                                <time datetime="2020-12-09T11:43:00">{{ formatDate(item.published) }}</time>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div class="mt-6 px-6">
                                            <button @click="viewAllNews"
                                                    class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                                View all
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    </div>

</template>

<script>

import {MENU_KEYS} from "@/master/constants";
import moment from 'moment';
import {find, map} from "lodash";
import {handleFetchGetStarted} from "@/master/views/Home/getstarted/handlers";
import Loading from "@/components/loading";
import {formatDay, orderedDaysObject} from "@/master/utils";

export default {
    name: "GetStarted",
    data() {
        return {
            loading: false,
            error: null,
            news: null,
            messages: null,
            contact: null,
            emailStats: null
        }
    },

    computed: {
        openingsHours() {
            if (this.contact.openingsHours) {
                let orderOpeningsHours = orderedDaysObject(this.contact.openingsHours);
                return map(Object.keys(orderOpeningsHours), key => {
                    return {
                        day: formatDay(key, 'nl'),
                        hours: this.contact.openingsHours[key]
                    }
                })
            }

            return []
        }
    },

    methods: {
        isToday(day) {
            const dateObj = new Date()
            const today = dateObj.toLocaleString("default", {weekday: "long"})
            return today.toLowerCase() === day.toLowerCase()
        },

        goToProfile() {
            const settingsBlock = find(this.$store.getters['master/application'].subMenus, sm => sm.methodName === MENU_KEYS.SETTINGS);
            if (settingsBlock) {
                this.$store.commit("master/setSubMenu", settingsBlock);
            }
        },

        goToMessage(message_id) {
            this.$store.commit('master/setSelectedMessage', message_id);
            this.viewAllMessages();
        },

        viewAllMessages() {
            const settingsBlock = find(this.$store.getters['master/application'].subMenus, sm => sm.methodName === MENU_KEYS.MESSAGES);
            if (settingsBlock) {
                this.$store.commit("master/setSubMenu", settingsBlock);
            }
        },

        goToNews(news_id) {
            this.$store.commit('master/setSelectedNews', news_id);
            this.viewAllNews();
        },

        viewAllNews() {
            const settingsBlock = find(this.$store.getters['master/application'].subMenus, sm => sm.methodName === MENU_KEYS.NEWS);
            if (settingsBlock) {
                this.$store.commit("master/setSubMenu", settingsBlock);
            }
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY, HH:mm')
        },

        async initialize() {
            try {
                this.loading = true;
                this.error = null;

                const payload = {}
                await handleFetchGetStarted(payload, (response) => {
                    this.messages = response.messages;
                    this.news = response.news;
                    this.emailStats = response.emailStats;
                    this.contact = response.contact;
                }, () => {
                    this.error = 'Loading news failed. Please try again.'
                })

                this.loading = false

            } catch (err) {
                this.error = 'Loading news failed. Please try again.'
                this.loading = false
            }
        },
    },

    components: {
        'autralis-loading-comp': Loading,
    },

    created() {
        this.initialize();
    }
}
</script>

<style scoped>

</style>