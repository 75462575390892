import axios from "axios";

export const handleFetchGetStarted = async (payload, onSuccess, onError) => {
    const response = await axios.get(
        '/manager/expert3/xp3/get-started/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading failed. Please try again.')
    }
}